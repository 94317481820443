import React, { useState } from 'react'
import { ComposedChart, ScatterChart, Scatter, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import barchartImage from './images/barchartImage.png'
import EditLogo from '../../../../images/rewampimages/edit.png'
import piechartimage from './images/piechartimage.png'
import barchartImage2 from './images/barchartImage2.png'
import piechartimage2 from './images/piechartimage2.png'
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
export default function RevenueOperationalExpenses() {
    const [edit, setEdit] = useState(false);
    const [nbfcFilter, setnbfcFilter] = useState('all')
    const [data,setData] =useState({
        grossProfitMarginPercentage: 79,
        OPEXRatioPercentage:68,
        EBITMarginRatio:30,
        NetProfitMarginRatio:15,
        operationalExpenses:[
            {
                name: 'Jan',
                General: 4000,
                Marketing: 2400,
                Sales: 4000,
                IT: 2400,
            },
            {
                name: 'Feb',
                General: 3000,
                Marketing: 1398,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'Mar',
                General: 2000,
                Marketing: 9800,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'Apr',
                General: 2780,
                Marketing: 3908,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'May',
                General: 1890,
                Marketing: 4800,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'Jun',
                General: 2390,
                Marketing: 3800,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'Jul',
                General: 3490,
                Marketing: 4300,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'Aug',
                General: 3490,
                Marketing: 4300,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'Sep',
                General: 3490,
                Marketing: 4300,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'Oct',
                General: 3490,
                Marketing: 4300,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'Nov',
                General: 3490,
                Marketing: 4300,
                Sales: 3000,
                IT: 1398,
            },
            {
                name: 'Dec',
                General: 3490,
                Marketing: 4300,
                Sales: 3000,
                IT: 1398,
            },
        ],
        earningBeforeTaxInterest:[
            {
                name: 'Jan',
                ebitActual: 4000,
                ebitTarget: 2400,
            },
            {
                name: 'Feb',
                ebitActual: 3000,
                ebitTarget: 1398,
            },
            {
                name: 'Mar',
                ebitActual: 2000,
                ebitTarget: 9800,
            },
            {
                name: 'Apr',
                ebitActual: 2780,
                ebitTarget: 3908,
            },
            {
                name: 'May',
                ebitActual: 1890,
                ebitTarget: 4800,
            },
            {
                name: 'Jun',
                ebitActual: 2390,
                ebitTarget: 3800,
            },
            {
                name: 'Jul',
                ebitActual: 3490,
                ebitTarget: 4300,
            },
            {
                name: 'Aug',
                ebitActual: 3490,
                ebitTarget: 4300,
            },
            {
                name: 'Sep',
                ebitActual: 3490,
                ebitTarget: 4300,
            },
            {
                name: 'Oct',
                ebitActual: 3490,
                ebitTarget: 4300,
            },
            {
                name: 'Nov',
                ebitActual: 3490,
                ebitTarget: 4300,
            },
            {
                name: 'Dec',
                ebitActual: 3490,
                ebitTarget: 4300,
            },
        ],
        revenue:[
            {
                name: 'Jan',
                growth: 3000,
                revenue: 4000,
                target: 2400,
            },
            {
                name: 'Feb',
                growth: 3000,
                revenue: 3000,
                target: 1398,
            },
            {
                name: 'Mar',
                growth: 3000,
                revenue: 2000,
                target: 9800,
            },
            {
                name: 'Apr',
                growth: 3000,
                revenue: 2780,
                target: 3908,
            },
            {
                name: 'May',
                growth: 3000,
                revenue: 1890,
                target: 4800,
            },
            {
                name: 'Jun',
                growth: 3000,
                revenue: 2390,
                target: 3800,
            },
            {
                name: 'Jul',
                growth: 3500,
                revenue: 3490,
                target: 4300,
            },
            {
                name: 'Aug',
                growth: 3040,
                revenue: 3490,
                target: 4300,
            },
            {
                name: 'Sep',
                growth: 2000,
                revenue: 3490,
                target: 4300,
            },
            {
                name: 'Oct',
                growth: 1000,
                revenue: 3490,
                target: 4300,
            },
            {
                name: 'Nov',
                growth: 1500,
                revenue: 3490,
                target: 4300,
            },
            {
                name: 'Dec',
                growth: 100,
                revenue: 3490,
                target: 4300,
            },
        ],
        incomeStatement:{
            revenue: '567',
            cogs: '5678',
            grossProfit: '678',
            opex: '678',
            sales: '6789',
            marketing: '678',
            it: '678',
            generalAdmin: '6789',
            otherIncome: '6789',
            Otherexpenses: '45678',
            ebit: '5678',
            interestTax: '8900',
            netProfit: '56789'
        }
    })

    const [tableData, settableData] = useState({
        revenue: '567',
        cogs: '5678',
        grossProfit: '678',
        opex: '678',
        sales: '6789',
        marketing: '678',
        it: '678',
        generalAdmin: '6789',
        otherIncome: '6789',
        Otherexpenses: '45678',
        EBIT: '5678',
        InterestTax: '8900',
        netProfit: '56789'
    })
    const [numbers, setNumbers] = useState([
        {
            name: 'Gross Profit Margin',
            image: barchartImage,
            value: '79'
        },
        {
            name: 'OPEX Ratio',
            image: piechartimage,
            value: '68'
        },
        {
            name: 'EBIT Margin',
            image: barchartImage2,
            value: '30'
        },
        {
            name: 'Net Profit Margin',
            image: piechartimage2,
            value: '15'
        },
    ])

    const operationalExpenseData = [
        {
            name: 'Jan',
            General: 4000,
            Marketing: 2400,
            Sales: 4000,
            IT: 2400,
            amt: 2400,
        },
        {
            name: 'Feb',
            General: 3000,
            Marketing: 1398,
            Sales: 3000,
            IT: 1398,
            amt: 2210,
        },
        {
            name: 'Mar',
            General: 2000,
            Marketing: 9800,
            Sales: 3000,
            IT: 1398,
            amt: 2290,
        },
        {
            name: 'Apr',
            General: 2780,
            Marketing: 3908,
            Sales: 3000,
            IT: 1398,
            amt: 2000,
        },
        {
            name: 'May',
            General: 1890,
            Marketing: 4800,
            Sales: 3000,
            IT: 1398,
            amt: 2181,
        },
        {
            name: 'Jun',
            General: 2390,
            Marketing: 3800,
            Sales: 3000,
            IT: 1398,
            amt: 2500,
        },
        {
            name: 'Jul',
            General: 3490,
            Marketing: 4300,
            Sales: 3000,
            IT: 1398,
            amt: 2100,
        },
        {
            name: 'Aug',
            General: 3490,
            Marketing: 4300,
            Sales: 3000,
            IT: 1398,
            amt: 2100,
        },
        {
            name: 'Sep',
            General: 3490,
            Marketing: 4300,
            Sales: 3000,
            IT: 1398,
            amt: 2100,
        },
        {
            name: 'Oct',
            General: 3490,
            Marketing: 4300,
            Sales: 3000,
            IT: 1398,
            amt: 2100,
        },
        {
            name: 'Nov',
            General: 3490,
            Marketing: 4300,
            Sales: 3000,
            IT: 1398,
            amt: 2100,
        },
        {
            name: 'Dec',
            General: 3490,
            Marketing: 4300,
            Sales: 3000,
            IT: 1398,
            amt: 2100,
        },
    ];
    const scatterchartdata = [
        {
            name: 'Jan',
            EBITActual: 4000,
            EBITTarget: 2400,
            amt: 2400,
        },
        {
            name: 'Feb',
            EBITActual: 3000,
            EBITTarget: 1398,
            amt: 2210,
        },
        {
            name: 'Mar',
            EBITActual: 2000,
            EBITTarget: 9800,
            amt: 2290,
        },
        {
            name: 'Apr',
            EBITActual: 2780,
            EBITTarget: 3908,
            amt: 2000,
        },
        {
            name: 'May',
            EBITActual: 1890,
            EBITTarget: 4800,
            amt: 2181,
        },
        {
            name: 'Jun',
            EBITActual: 2390,
            EBITTarget: 3800,
            amt: 2500,
        },
        {
            name: 'Jul',
            EBITActual: 3490,
            EBITTarget: 4300,
            amt: 2100,
        },
        {
            name: 'Aug',
            EBITActual: 3490,
            EBITTarget: 4300,
            amt: 2100,
        },
        {
            name: 'Sep',
            EBITActual: 3490,
            EBITTarget: 4300,
            amt: 2100,
        },
        {
            name: 'Oct',
            EBITActual: 3490,
            EBITTarget: 4300,
            amt: 2100,
        },
        {
            name: 'Nov',
            EBITActual: 3490,
            EBITTarget: 4300,
            amt: 2100,
        },
        {
            name: 'Dec',
            EBITActual: 3490,
            EBITTarget: 4300,
            amt: 2100,
        },
    ];
    const RevenueData = [
        {
            name: 'Jan',
            Growth: 3000,
            Revenue: 4000,
            Target: 2400,
            amt: 2400,
        },
        {
            name: 'Feb',
            Growth: 3000,
            Revenue: 3000,
            Target: 1398,
            amt: 2210,
        },
        {
            name: 'Mar',
            Growth: 3000,
            Revenue: 2000,
            Target: 9800,
            amt: 2290,
        },
        {
            name: 'Apr',
            Growth: 3000,
            Revenue: 2780,
            Target: 3908,
            amt: 2000,
        },
        {
            name: 'May',
            Growth: 3000,
            Revenue: 1890,
            Target: 4800,
            amt: 2181,
        },
        {
            name: 'Jun',
            Growth: 3000,
            Revenue: 2390,
            Target: 3800,
            amt: 2500,
        },
        {
            name: 'Jul',
            Growth: 3500,
            Revenue: 3490,
            Target: 4300,
            amt: 2100,
        },
        {
            name: 'Aug',
            Growth: 3040,
            Revenue: 3490,
            Target: 4300,
            amt: 2100,
        },
        {
            name: 'Sep',
            Growth: 2000,
            Revenue: 3490,
            Target: 4300,
            amt: 2100,
        },
        {
            name: 'Oct',
            Growth: 1000,
            Revenue: 3490,
            Target: 4300,
            amt: 2100,
        },
        {
            name: 'Nov',
            Growth: 1500,
            Revenue: 3490,
            Target: 4300,
            amt: 2100,
        },
        {
            name: 'Dec',
            Growth: 100,
            Revenue: 3490,
            Target: 4300,
            amt: 2100,
        },
    ];


    const handleInputchange = (e) => {
        const { name, value } = e.target;
        settableData({
            ...tableData,
            [name]: value
        })
    }

    const handleSaveBtn = () => {
        setEdit(false)
    }

    const handleChange = (e) => {

        setnbfcFilter(e.target.value)

    };

    return (
        <>
            <div className="RevenueOperationalExpenses">
            <div className="MonthDateFilter" style={{marginBottom:'40px'}}>
                    <div className="row">

                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={handleChange} style={{ width: '100%' }} >
                                    <option value="all">ALL</option>
                                    <option value="yashikfinlease">Yashik Finlease</option>
                                    {/* <option value="citra">Citra</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <button className='FilterApply'>Apply Filter</button>
                        </div>

                    </div>
                </div>
                <div className="LeftSide">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-xs-12 newPadding" style={{ marginTop: '10px' }}>
                            {
                                numbers?.map((value, index) => {
                                    return (
                                        <div className="boxes" key={index}>
                                            <div className="heading">
                                                <h5>{value?.name}</h5>
                                            </div>
                                            <div className="subbox">
                                                <div className="image">
                                                    <img src={value?.image} alt="chartImage" width={'50%'} />
                                                </div>
                                                <div className="text">
                                                    <h4 style={{ color: index % 2 == 0 ? '#11c711' : '#61acf3' }}>{value?.value + "%"}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-lg-5 col-md-6 col-xs-12 newPadding" style={{ marginTop: '10px' }}>
                            <div className="chart">
                                <div className="heads">
                                    <h5>Operational Expenses</h5>
                                </div>
                                <div className="legends">
                                    <div className="legendboxes">
                                        <div className="square" style={{ backgroundColor: '#f0bf13' }}></div>
                                        <h6>General</h6>
                                    </div>
                                    <div className="legendboxes">
                                        <div className="square" style={{ backgroundColor: '#11c711' }}></div>
                                        <h6>Marketing</h6>
                                    </div>
                                    <div className="legendboxes">
                                        <div className="square" style={{ backgroundColor: '#7f34b2' }}></div>
                                        <h6>Sales</h6>
                                    </div>
                                    <div className="legendboxes">
                                        <div className="square" style={{ backgroundColor: '#0083ff' }}></div>
                                        <h6>IT</h6>
                                    </div>
                                </div>
                                <div className="subs" style={{ height: '154px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={operationalExpenseData}
                                            margin={{
                                                top: 20,
                                                right: 0,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="General" stackId="a" fill="#f0bf13" />
                                            <Bar dataKey="Marketing" stackId="a" fill="#11c711" />
                                            <Bar dataKey="Sales" stackId="a" fill="#7f34b2" />
                                            <Bar dataKey="IT" stackId="a" fill="#0083ff" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <div className="chart" style={{ marginTop: '20px' }}>
                                <div className="heads">
                                    <h5>Earning Before Interest & Taxes</h5>
                                </div>
                                <div className="legends">
                                    <div className="legendboxes widthchange">
                                        <div className="square" style={{ backgroundColor: '#0083ff' }}></div>
                                        <h6>EBIT Actual</h6>
                                    </div>
                                    <div className="legendboxes widthchange">
                                        <div className="square" style={{ backgroundColor: '#9800ff' }}></div>
                                        <h6>EBIT Target</h6>
                                    </div>
                                </div>
                                <div className="subs" style={{ height: '157px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ScatterChart
                                            width={560}
                                            height={300}
                                            data={scatterchartdata}
                                            margin={{
                                                top: 20,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                            }}
                                        >
                                            <CartesianGrid />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Scatter type="monotone" dataKey="EBITActual" fill="#0083ff" line />
                                            <Scatter type="monotone" dataKey="EBITTarget" fill="#9800ff" line={{ strokeDasharray: '5 5' }} shape={<CustomDot />} />
                                        </ScatterChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <div className="chart" style={{ marginTop: '20px' }}>
                                <div className="heads">
                                    <h5>Revenue</h5>
                                </div>
                                <div className="legends">
                                    <div className="legendboxes widthchange">
                                        <div className="square" style={{ backgroundColor: '#66bf88' }}></div>
                                        <h6>Growth</h6>
                                    </div>
                                    <div className="legendboxes widthchange">
                                        <div className="square" style={{ backgroundColor: '#9800ff' }}></div>
                                        <h6>Target</h6>
                                    </div>
                                    <div className="legendboxes widthchange">
                                        <div className="square" style={{ backgroundColor: '#0083ff' }}></div>
                                        <h6>Revenue</h6>
                                    </div>
                                </div>
                                <div className="subs" style={{ height: '154px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={300}
                                            data={RevenueData}
                                            barSize={20}
                                            margin={{
                                                top: 20,
                                                right: 0,
                                                left: 0,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} horizontal={false} />
                                            <XAxis dataKey="name" />
                                            <YAxis yAxisId="left" orientation="left" />
                                            <YAxis yAxisId="right" orientation="right" />
                                            <Tooltip />
                                            <Bar yAxisId="right" dataKey="Growth" fill="#66bf88" />
                                            <Scatter type="monotone" yAxisId="left" dataKey="Revenue" fill="#0083ff" line />
                                            <Scatter type="monotone" yAxisId="left" dataKey="Target" fill="#9800ff" line={{ strokeDasharray: '5 5' }} shape={<CustomDot />} />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 newPadding" style={{ marginTop: '10px' }}>
                            <div className="rightside">
                                <div className="outerBox">
                                    <div className="innerBox">
                                        <div className="HeadingBox" style={{ backgroundColor: '#0e5768' }}>
                                            <h5>Income statement</h5>
                                            <div className="circleedit" onClick={() => setEdit(!edit)}>
                                                {
                                                    edit ?
                                                    <VisibilitySharpIcon  style={{fontSize:'25px',color:'#fff'}}/>
                                                    :
                                                    <img src={EditLogo} alt="editicon" width={'100%'} />
                                                }
                                                
                                            </div>
                                        </div>


                                        <div className="box">
                                            <div className="table">
                                                {
                                                    Object.keys(tableData)?.length > 0 ?
                                                        <div className="phocket-table-new newtable">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Revenue</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.revenue} className="inputTag" name="revenue" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.revenue}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>COGS</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.cogs} className="inputTag" name="cogs" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.cogs}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Gross Profit</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.grossProfit} className="inputTag" name="grossProfit" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.grossProfit}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>OPEX</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.opex} className="inputTag" name="opex" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.opex}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Sales</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.sales} className="inputTag" name="sales" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.sales}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Marketing</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.marketing} className="inputTag" name="marketing" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.marketing}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>IT</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.it} className="inputTag" name="it" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.it}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>General & Admin</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.generalAdmin} className="inputTag" name="generalAdmin" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.generalAdmin}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Other Income</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.otherIncome} className="inputTag" name="otherIncome" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.otherIncome}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Other Expenses</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.Otherexpenses} className="inputTag" name="Otherexpenses" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.Otherexpenses}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>EBIT</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.EBIT} className="inputTag" name="EBIT" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.EBIT}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Interest & Tax</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.InterestTax} className="inputTag" name="InterestTax" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.InterestTax}</td>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Net Profit</th>
                                                                        {
                                                                            edit ?
                                                                                <td><input type="text" value={tableData?.netProfit} className="inputTag" name="netProfit" onChange={(e) => handleInputchange(e)} /></td>
                                                                                :
                                                                                <td>{tableData?.netProfit}</td>
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        : ''}
                                            </div>

                                            {
                                                edit ?
                                                    <div className="btns">
                                                        <button className='savebtn' onClick={() => handleSaveBtn()}>Save</button>
                                                    </div>
                                                    : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const CustomDot = (props) => {
    return null;
};