import React, { useEffect, useState } from "react";
import { getCategoryPointers, getCategoryList, saveCategory, savePointerMap, updatePointerMap } from "./actionCreatorScore";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const Pointers = () => {
    const [categoryData, setCategoryData] = useState('');
    const [name, setname] = useState('');
    const [loader, setLoader] = useState(false);
    const [categoryDataList, setCategoryDatalist] = useState('');
    const [jsonData, setjsonData] = useState({});
    const [isEditing, setIsEditing] = useState(false); // To track if editing
    const [editingPointerId, setEditingPointerId] = useState(null); // Track which pointer is being edited

    useEffect(() => {
        getdata();
    }, []);

    const getdata = () => {
        setLoader(true);
        getCategoryList(callback => {
            if (callback) {
                setCategoryData(callback);
                setLoader(false);
            } else {
                setLoader(false);
            }
        });
        getCategoryPointers(callback => {
            if (callback) {
                setCategoryDatalist(callback);
                setLoader(false);
            } else {
                setLoader(false);
            }
        });
    };

    const saveCategoryApi = () => {
        setLoader(true);
        saveCategory(name, callback => {
            getdata();
        });
    };

    const changeHandler = (e) => {
        setname(e.target.value);
    };

    const pointerHandler = (e, type) => {
        let changeData = { ...jsonData };
        changeData[type] = e.target.value;
        setjsonData(changeData);
    };

    const editPointer = (pointer) => {
        setIsEditing(true);
        setEditingPointerId(pointer.id);
        setjsonData({
            categoryId: pointer.categoryId,
            scoreType: pointer.scoreType,
            score: pointer.score,
            mandatory: pointer.mandatoryScoring,
            id: pointer.id
        });
    };

    const saveData = () => {
        if (jsonData.categoryId && jsonData.scoreType && jsonData.score && jsonData.mandatory) {
            if (isEditing === false) {
                savePointerMap(jsonData.categoryId, jsonData.scoreType, jsonData.score, jsonData.mandatory, callback => {
                    console.log(callback);
                    getdata();
                    setIsEditing(false);
                    setEditingPointerId(null);
                    setjsonData({});
                });
            }
            if (isEditing === true) {
                updatePointerMap(jsonData.categoryId, jsonData.scoreType, jsonData.score, jsonData.mandatory, jsonData.id, callback => {
                    console.log(callback);
                    getdata();
                    setIsEditing(false);
                    setEditingPointerId(null);
                    setjsonData({});
                });
            }
        }
    };

    return (
        <div className="">
            <div className="row">
                <div className="col-sm-6 col-xs-12">
                    <div className="box-score">
                        <h3>Category Pointers Listing</h3>
                        <div className="phocket-table-new">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Pointer</th>
                                        <th className="text-center">Category Name</th>
                                        <th className="text-center">Mandatory</th>
                                        <th className="text-center">Score</th>
                                        {/* <th className="text-center">Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader === false && categoryDataList !== '' && categoryDataList.length > 0 && categoryDataList.map((data, i) => (
                                        <tr key={i}>
                                            <td>{moment(data.addedOn).format('DD-MM-YYYY')}</td>
                                            <td>{data.scoreType}</td>
                                            <th className="text-center">{data.categoryName}</th>
                                            <th className="text-center">
                                                <input type="checkbox" readOnly checked={data.mandatoryScoring} />
                                            </th>
                                            <td className="text-center">{data.score}</td>
                                            {/* <td className="text-center">
                                                <button className="btn btn-sm btn-primary" onClick={() => editPointer(data)}>Edit</button>
                                            </td> */}
                                        </tr>
                                    ))}
                                    {loader && <SkeletonTable />}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-xs-12">
                    <div className="box-score" style={{ overflow: 'hidden' }}>
                        <h3>{isEditing ? 'Edit' : 'Add'} Pointer</h3>
                        <label style={{ marginTop: '20px' }}>Select Category</label>
                        <select style={{ width: '70%' }} className="form-control" onChange={(e) => pointerHandler(e, 'categoryId')} value={jsonData.categoryId || ''}>
                            <option>Select Value</option>
                            {categoryData && categoryData.map((data, i) => (
                                <option key={i} value={data.categoryId}>{data.name}</option>
                            ))}
                        </select>

                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 col-xs-12">
                                <label>Particulars</label>
                                <input className="form-control" placeholder="Particulars" onChange={(e) => pointerHandler(e, 'scoreType')} value={jsonData.scoreType || ''} />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Score</label>
                                <input className="form-control" placeholder="Score" onChange={(e) => pointerHandler(e, 'score')} value={jsonData.score || ''} />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Mandatory?</label>
                                <select className="form-control" onChange={(e) => pointerHandler(e, 'mandatory')} value={jsonData.mandatory || ''}>
                                    <option>Select Value</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                        </div>

                        <div className="text-center" style={{ marginTop: '40px' }}>
                            <button className="btn btn-primary" onClick={saveData}>{isEditing ? 'Update' : 'Save'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Skeleton loader component for readability
const SkeletonTable = () => (
    <>
        {[...Array(6)].map((_, i) => (
            <tr key={i}>
                <td><Skeleton height={40} /></td>
                <td><Skeleton height={40} /></td>
                <td><Skeleton height={40} /></td>
            </tr>
        ))}
    </>
);

export default Pointers;
