import React from "react";
import { PLACEHOLDER, STRING, TYPE } from "../../../utils/Constant";
import DatePicker from 'react-datepicker'

const TentativePopup = ({
    nachSourceData,
    errorMsg,
    disableUpDownScroll,
    showCheckBoxEsign,
    esignDisableHandler,
    esignDisable,
    company,
    nbfcList,
    nbfcHandler,
    tentativeData,
    tentativeSaveHandler,
    tentativeDetail,
    userDetails,
    emiTableData,
    nbfc,
    belowoneLakh,
    belowoneLakhState,
    rangeVreedhi,
    salaryhandler,
    salaryDateToShow,
    repeatUser,
    tentativePopup, closePopup, errorMessage, showButton, getFailureDescriptions, mandatoryData }) => (
    <div   >
        {/* {mandatoryData && mandatoryData.final_decision && mandatoryData.final_decision.status === "true" ? */}
            <div >

                {/* <h3 className='text-center blue-text'>Tentative Approve</h3> */}
                <div className='row from-group' style={{ marginTop: '14px' }}>
                    {userDetails.typeOfProduct == 'Loan' && userDetails.loanFrom !== 'vreedhi' ?

                        <div className='col-xs-12 col-sm-12 font-12px' style={{ marginBottom: '15px' }}>
                            <input type="checkbox" onChange={(e) => belowoneLakh(belowoneLakhState)} checked={belowoneLakhState} />&nbsp;<b>Tick here if you want to approve for amount greater than 1Lac</b>
                        </div>
                        : ""}
                    <div className={'col-xs-6 col-sm-6'}>

                        {userDetails.typeOfProduct == 'Loan' && belowoneLakhState == false ? (
                            <div>
                                <label className='font-12px'>Amount</label>
                                <input
                                    type='number'
                                    className='form-control'
                                    onChange={e => tentativeData(e, 'amount', '')}
                                    value={tentativeDetail.tentativeAmount}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        ) : userDetails.typeOfProduct == 'Gift Card'
                            // && userDetails.giftCardType == 'Flipkart' 
                            ? (
                                <div>
                                    <label className='font-12px'>Select Amount</label>
                                    <select
                                        className='form-control'
                                        onChange={e => tentativeData(e, 'amount', '')}
                                        value={tentativeDetail.tentativeAmount}
                                        onWheel={() => disableUpDownScroll()}

                                    >
                                        <option value='' disabled>
                                            Select Amount
                                        </option>
                                        <option value='5000'>5000</option>
                                        <option value='10000'>10000</option>
                                        <option value='15000'>15000</option>
                                        <option value='20000'>20000</option>
                                        <option value='25000'>25000</option>
                                        <option value='30000'>30000</option>
                                    </select>
                                </div>
                            ) : userDetails.typeOfProduct == 'Loan' && belowoneLakhState == true ?
                                <div>
                                    <label className='font-12px'>Select Amount</label>
                                    <select
                                        required
                                        className='form-control'
                                        value={tentativeData.tentativeAmount}
                                        onChange={e => tentativeData(e, 'amount', '')}
                                    >
                                        <option value='' disabled selected >
                                            Select Amount
                                        </option>
                                        <option value='110000'>110000</option>
                                        <option value='120000'>120000</option>
                                        <option value='130000'>130000</option>
                                        <option value='140000'>140000</option>
                                        <option value='150000'>150000</option>
                                        <option value='160000'>160000</option>
                                        <option value='170000'>170000</option>
                                        <option value='180000'>180000</option>
                                        <option value='190000'>190000</option>
                                        <option value='200000'>200000</option>

                                    </select>
                                </div>
                                : ""}
                    </div>

                    <div className='col-xs-6 col-sm-6'>
                        {nbfcList != null && nbfcList != '' ? (
                            <div className=''>
                                <label className='font-12px'>Select NBFC</label>

                                <select
                                    className='form-control'
                                    value={nbfc}
                                    onChange={e => nbfcHandler(e)}
                                >

                                    {nbfcList.map((data, i) => {
                                        return (
                                            <option key={i} value={data.nbfcId}>
                                                {data.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    {userDetails.loanFrom === 'vreedhi' ?
                        <div className='col-xs-6 col-sm-6'>
                            <label className='font-12px'>Installment(weeks)</label>
                            <input
                                type='number'
                                className='form-control'
                                id='phocketEMI'
                                value={tentativeDetail.selectEMI}
                                onChange={e => tentativeData(e, TYPE.SELECT_EMI, '')}

                            />
                            {rangeVreedhi !== '' ?
                                <div style={{ color: 'red', fontSize: '12px' }}>{rangeVreedhi}</div>
                                : ""}
                        </div>
                        : ""}
                    {userDetails.typeOfProduct == 'Gift Card' ?

                        <div className='col-xs-6 col-sm-6'>
                            <div>
                                <label className='font-12px'>Select Company</label>

                                <select
                                    className='form-control' value={company}
                                    onChange={e => tentativeData(e, 'company', '')}
                                >
                                    <option value='' >
                                        Select Company
                                    </option>
                                    <option value='Flipkart'>Flipkart</option>
                                    <option value='Amazon'>Amazon</option>
                                </select>
                            </div>


                        </div>
                        : ""}

                </div>
                <div className='row from-group'>

                    {userDetails.typeOfProduct === 'Loan' && userDetails.loanFrom !== 'vreedhi' ?
                        <div className='col-sm-6 col-xs-12'>
                            <label className='font-12px'>Salary Day</label>
                            <select
                                required
                                className='form-control'
                                value={salaryDateToShow}
                                onChange={e => salaryhandler(e)}
                                onClick={e => salaryhandler(e)}
                            >
                                <option value='' disabled selected>
                                    Select Salary Day
                                </option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                                <option value='11'>11</option>
                                <option value='12'>12</option>
                                <option value='13'>13</option>
                                <option value='14'>14</option>
                                <option value='15'>15</option>
                                <option value='16'>16</option>
                                <option value='17'>17</option>
                                <option value='18'>18</option>
                                <option value='19'>19</option>
                                <option value='20'>20</option>
                                <option value='21'>21</option>
                                <option value='22'>22</option>
                                <option value='23'>23</option>
                                <option value='24'>24</option>
                                <option value='25'>25</option>
                                <option value='26'>26</option>
                                <option value='27'>27</option>
                                <option value='28'>28</option>
                                <option value='29'>29</option>
                                <option value='30'>30</option>
                                <option value='31'>31</option>

                            </select>



                        </div>
                        : ""}

                    {userDetails.loanFrom !== 'vreedhi' ?
                        <div className='col-xs-6 col-sm-6'>

                            {userDetails.typeOfProduct == 'Loan' ? (
                                <div>
                                    <label className='font-12px'>Select EMI</label>
                                    <select
                                        required
                                        className='form-control'
                                        id='phocketEMI'
                                        value={tentativeDetail.selectEMI}
                                        onChange={e => tentativeData(e, TYPE.SELECT_EMI, '')}
                                    >
                                        <option value='' disabled selected>
                                            {PLACEHOLDER.SELECT_EMI}
                                        </option>
                                        <option value={STRING.FIRST_EMI}>
                                            {STRING.FIRST_EMI + ' Month'}
                                        </option>
                                        {tentativeDetail.tentativeAmount >= 5000 ? (
                                            <option value={STRING.SECOND_EMI}>
                                                {STRING.SECOND_EMI + ' Months'}
                                            </option>
                                        ) : null}
                                        {tentativeDetail.tentativeAmount >= 11000 ? (
                                            <option value={STRING.THIRD_EMI}>
                                                {STRING.THIRD_EMI + ' Months'}
                                            </option>
                                        ) : null}
                                        {tentativeDetail.tentativeAmount >= 21000 ? (
                                            <option value={STRING.SIXTH_EMI}>
                                                {STRING.SIXTH_EMI + ' Months'}
                                            </option>
                                        ) : ""}
                                        {tentativeDetail.tentativeAmount >= 61000 ? (

                                            <option value='12'>
                                                12 Months
                                            </option>
                                        ) : null}
                                    </select>
                                </div>
                            ) : userDetails.typeOfProduct == 'Gift Card' ? (
                                <div>
                                    <label className='font-12px'>Select EMI</label>
                                    <select
                                        required
                                        className='form-control'
                                        id='phocketEMI'
                                        value={tentativeDetail.selectEMI}
                                        onChange={e => tentativeData(e, TYPE.SELECT_EMI, '')}
                                    >
                                        <option value='' disabled selected>
                                            {PLACEHOLDER.SELECT_EMI}
                                        </option>

                                        <option value={STRING.THIRD_EMI}>
                                            {STRING.THIRD_EMI + ' Months'}
                                        </option>
                                    </select>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        : ""}


                    {emiTableData.length > 0
                        ? emiTableData.map((emi, i) => {
                            return (
                                <div className='col-xs-6 col-sm-6' key={i}>
                                    <label className='font-12px'>
                                        {i + 1} Repayment Date
                                    </label>
                                    <DatePicker
                                        disabled={userDetails.loanFrom === 'vreedhi' ? true : false}
                                        value={tentativeDetail['tentativeTenure' + i]}
                                        selected={tentativeDetail['lastEmiDate' + i]}
                                        onChange={e => tentativeData(e, 'tenure', i)}
                                        className='form-control'
                                        placeholderText='select Date'
                                        dateFormat='DD/MM/YYYY'
                                        id={'RepaymentDate_' + i}
                                        fixedHeight
                                    />
                                </div>
                            )
                        })
                        : null}
                </div>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <span className="text-center text-danger">{errorMsg}</span>
                </div>
                <span className="text-center text-danger">{errorMessage}</span>
                <div style={{ marginTop: '10px' }}>
                    <b>NACH:</b>   {userDetails.nachStatus != null && userDetails.nachStatus != null ? (
                        userDetails.nachStatus == true ? (
                            <span className='red-text' style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>Active -{nachSourceData.signDesk ? 'Sign Desk' : nachSourceData.billDesk ? 'Billdesk' : nachSourceData.razorPay ? (nachSourceData.razorPay)?.toUpperCase() : nachSourceData.techProcess ? 'Techprocess' : ''}</span>
                        ) : (
                            <span className='red-text' style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>Inactive</span>
                        )
                    ) : (
                        ''
                    )}
                </div>
                {console.log(getFailureDescriptions())}
                <>
                    {showButton ?
                        <div className='row'>
                            <div className='col-xs-12 ' style={{ marginTop: '20px' }}>
                                {showCheckBoxEsign && repeatUser.repeatUser === true ? <><input type='checkbox' style={{ fontSize: '25px', cursor: 'pointer' }} checked={esignDisable} onChange={(e) => esignDisableHandler(e)} />&nbsp; Disable Esign </> : ""}
                                <br />
                                <br />
                                <button
                                    className='btn btn-primary'
                                    onClick={e => tentativeSaveHandler()}
                                >
                                    SAVE
                                </button>
                            </div>
                        </div>
                        : ""}
                </>
            </div>
                     {/* : getFailureDescriptions()} */}

    </div>
)


export default TentativePopup