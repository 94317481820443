import React from 'react'
const Bank = ({ pageNumber, refresh, selectAll, paginationHandler, admin, changehandler, allUserDetail, paymentAndVerificationHandler, paymentData, ApplicationId, loanIdData }) => {

    return (

        <div className="container-fluid">
            <div className="row form-group">
                <div className="col-xs-12">
                    <h3 className="text-center blue-text">Disburse By ICICI Bank</h3>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-xs-12 col-sm-3">

                </div>
                <div className="col-xs-12 col-sm-6 text-center">
                    <div className="" style={{ display: 'inline-flex' }}>
                        <div className="" >
                            <button className='profile-save-btn' onClick={() => paymentAndVerificationHandler('verify')}>Verify Payment</button>
                        </div>
                        &nbsp;
                        <div className="col-xs-12 col-sm-3">
                            <button className='payment-btn' onClick={() => paymentAndVerificationHandler('payment')} >Make Payment</button>

                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-3">


                </div>
            </div>
            <div className='row text-center'>
                <div className='col-sm-2'>
                    <label style={{ fontSize: '18px' }}>Select All </label>&nbsp;&nbsp;
                    <input type='checkbox' onChange={(e) => selectAll(e)} />
                </div>
                <div className='col-sm-8 text-end'>
                </div>
                <div className='col-sm-2 text-end'>
                    <button className='profile-save-btn' onClick={() => refresh()}>Refresh</button>
                </div>
            </div>
            <div className='row'>

                <div className='col-sm-12 col-xs-12'>
                    <div>
                        <div className="overflow-x-auto">

                            <table className="phocket-table">
                                <thead>
                                    <tr>
                                        <th>EMI Type</th>
                                        <th>Loan Id</th>
                                        <th>application id</th>
                                        <th>Name</th>
                                        <th>Approved Date</th>
                                        <th>Loan Amt</th>
                                        <th>Pro. Fee</th>
                                        <th>Net Payment</th>
                                        <th>Bank Name</th>
                                        <th>A/c No.</th>
                                        <th>IFSC Code</th>
                                        <th>Make Payment/Verify Payment</th>
                                        {paymentData !== '' ?
                                            <th>Payment Status</th>
                                            : ""}

                                    </tr>
                                </thead>
                                <tbody>
                                    {allUserDetail ? allUserDetail.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{data.loanEmi}</td>
                                                <td>{data.applicationLoanId}</td>
                                                <td>{data.applicationId}</td>
                                                <td style={{ whiteSpace: 'break-spaces' }}>{data.userName}</td>
                                                <td>{data.approveDate}</td>
                                                <td>{data.loanAmount}</td>
                                                <td>{data.processingFee}</td>
                                                <td>{data.netdisbursmentAmount}</td>
                                                <td>{data.bankName}</td>
                                                <td>{data.accountNo}</td>
                                                <td>{data.ifscCode}</td>
                                                <td><input id={'disburseIcici' + i} type='checkbox' onChange={(e) => changehandler(e, data)} /></td>
                                                {paymentData !== '' ? Object.keys(paymentData).includes(data.loanId) ?
                                                    // console.log((paymentData[data.loanId]))
                                                    <td>{(paymentData[data.loanId]) !== null && (paymentData[data.loanId]) !== undefined ? Object.values(paymentData[data.loanId]) : "null"}</td>
                                                    : "" : ""}
                                                {/* {Object.keys(ids).includes(data.loanId) ?
                                                    <td>{Object.values(ids[data.loanId])}</td>
                                                    : ""} */}
                                                {/* <td></td> */}

                                            </tr>
                                        )
                                    }) : ""}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                    <div className="col-sm-4 col-xs-12  phoc text-center" >On this Page:<b>{allUserDetail.length}</b>&nbsp;&nbsp; PageNumber: <b>{pageNumber}</b></div>
                    <div className="col-sm-4 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
            </div>
        </div>
    )
}
export default Bank