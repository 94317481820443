import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'; 
import GreenTick from '../../../../images/rewampimages/greentick.png';
import UnverifiedLogo from '../../../../images/rewampimages/unverifiedCross.png';
import { getFreshChecklistApi } from "../AdminActionCreator.component";

const ChecklistFresh = ({ userDetail }) => {
    const [dataSet, SetData] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getFreshChecklistApi(userDetail, callBack => {
            console.log(callBack);
            SetData(callBack);
            setLoading(false); 
        });
    }, [userDetail]);

    return (
        <div className="details-new">
            <div className="phocket-table-ops">
                <div style={{ padding: '10px' }}>Fresh Checklist</div>
                <table className="phocket-table-ops" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ borderTop: '1px solid #fff', borderLeft: '1px solid #fff' }}>Checking Points</th>
                            <th style={{ borderTop: '1px solid #fff' }}>Status</th>
                            <th style={{ borderTop: '1px solid #fff', borderRight: '1px solid #fff' }}>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            Array(5).fill().map((_, index) => (
                                <tr key={index}>
                                    <td><Skeleton width={150}  height={50}  /></td>
                                    <td className="text-center"><Skeleton width={50} height={50} /></td>
                                    <td><Skeleton width={100}  height={50}  /></td>
                                </tr>
                            ))
                        ) : (
                            dataSet !== '' ? Object.keys(dataSet).map(key => {
                                const dataItem = dataSet[key];
                                if (key === 'final_decision' || dataItem.value === 'final_decision'||key === 'status' || dataItem.value === 'status') return null;
                                return dataItem ? (
                                    <tr key={key}>
                                        <td>{key.replace(/_/g, ' ')}</td>
                                        <td className="text-center">
                                            <img src={dataItem.status === "true" ? GreenTick : UnverifiedLogo} width='27px' alt="status" />
                                        </td>
                                        <td>{(dataItem.value).includes('html')?<div 
                                          dangerouslySetInnerHTML={{
                                        __html: dataItem.value
                                    }}></div>:dataItem.value}</td>
                                    </tr>
                                ) : (
                                    <tr key={key}>
                                        <td colSpan={3}>Invalid data</td>
                                    </tr>
                                );
                            }) : ""
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ChecklistFresh;
