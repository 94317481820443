import React from 'react'
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import { ROLE } from '../../../../utils/Constant'

const ManualAssign = ({ typeInput, myRef, filterHandler, getLoaderState, getLoanLead, allAdminList, allUserDetail, allStatus, manualAssignCheck, loanIdForAssign,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow, paginationHandler,
    dataFilterState, followUpStatus, dataFilter, loadarState, assignProceedHandler,
    userDetailHandler, admin }) => (
    <div className="container-fluid">
        {/* <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text text-center">Manual Assign Leads</h3>
            </div>
        </div> */}
        <div className='row'>
            <div className='col-sm-3 col-xs-12'>
                <span className="main-search" >
                    <select className="form-control " style={{ background: '#DBE9FF', fontWeight: '700', width: '150px' }} onChange={e => { filterHandler(e) }}>
                        <option value="applicationNo">Application no</option>
                        <option value="email">Email</option>
                        <option value="mobileNo">Mobile No.</option>
                        <option value="date">Date</option>
                        <option value="assignedTo">Assigned To</option>
                    </select>
                    <span className="main-search">
                        {typeInput !== 'assignedTo' && typeInput !== 'date' ?
                            <input type={typeInput !== 'date' ? "text" : 'date'} style={{ width: '250px', background: '#fff' }} ref={myRef} className="form-control" onChange={e => dataFilter(e)} placeholder="Search.." />
                            : ""}
                        {typeInput === 'date' ?
                            <input type={typeInput !== 'date' ? "text" : 'date'} style={{ width: '250px', background: '#fff' }} ref={myRef} className="form-control" onChange={e => dataFilter(e)} placeholder="Search.." />
                            : ""}
                        {typeInput === 'assignedTo' ?
                            <select style={{ width: '200px', borderBottomLeftRadius: '0', borderTopLeftRadius: '0', borderBottomRightRadius: '10px', borderTopRightRadius: '10px', borderRight: '1px solid #A8C8FF', background: '#fff' }} className='form-control' placeholder='Email Id' onChange={e => dataFilter(e, "assignedTo")}>
                                <option value=''>Select Email</option>
                                {allAdminList && allAdminList.map((data, i) => {
                                    console.log(data.emailId)
                                    if (data.emailId !== 'mohit@phocket.in') {
                                        return (
                                            <option value={data.emailId}>{data.emailId}</option>
                                        )
                                    }
                                })}
                            </select>
                            : ""}
                    </span>
                </span>


            </div>
            <div className='col-sm-2 col-xs-12'>
                <button className='btn btn-default reset-btn' style={{ marginTop: '0px' }} onClick={e => paginationHandler('refresh')} ><i className='fa fa-repeat'>&nbsp;&nbsp;</i>Reset Filter</button>

            </div>
        </div>
        {/* <div className="row">
            <ul className="loan-lead-search">
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> By Status</label>
                    <select className="form-control" value={followUpStatus} onChange={(e) => dataFilter(e, "status")}>
                        <optgroup className="display-none">
                            <option value="">Select Status</option>
                        </optgroup>
                        <optgroup label="Status">
                            {allStatus != null && allStatus != undefined && allStatus != '' ?
                                allStatus.map((status, i) => {
                                    return (
                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                    );
                                }) : ""}
                        </optgroup>
                    </select>
                </li>
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> Application No.</label>
                    <input type="number" className="form-control" value={dataFilterState.searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} />
                </li>
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> Email ID</label>
                    <input type="email" className="form-control" value={dataFilterState.searchByemail} onChange={e => dataFilter(e, "email")} />
                </li>
                <li>
                    <label className="font-12px margin-0" ><span className="fa fa-search" /> Mobile No.</label>
                    <input type="number" value={dataFilterState.searchBymobile} onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
                </li>
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> Date</label>
                    <DatePicker
                        value={dataFilterState.searchByDate}
                        selected={dataFilterState.searchByDateShow}
                        onChange={e => dataFilter(e, "date")}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                    />
                </li>
                <li>
                    <label style={{ marginTop: '20px' }}>Assigned To</label>
                    <select className='form-control' placeholder='Email Id' onChange={e => dataFilter(e, "assignedTo")}>
                        <option value=''>Select Email</option>
                        {allAdminList && allAdminList.map((data, i) => {
                            return (
                                <option value={data.emailId}>{data.emailId}</option>
                            )
                        })}
                    </select>
                </li>

            </ul>
        </div> */}

        <div className=" form-group">
            <div className="col-xs-12 overflow-auto phocket-table-new">

                {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                    <table className=' '>
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Assigned To</th>
                                <th>Loan Apply Date</th>
                                <th> Application Status</th>
                                <th>Application Id</th>
                                <th>User Name</th>
                                <th>Email Id</th>
                                <th>Mobile Number</th>

                            </tr>
                        </thead>
                        <tbody>
                            {allUserDetail.map((data, i) => {
                                return (
                                    <tr>
                                        <td>
                                            <div>
                                                <input className="phocket-checkbox" type="checkbox" id={"manualAssign_" + data.loanId} onChange={(e) => manualAssignCheck(e, data)} checked={data.assign} />
                                                <label htmlFor={"manualAssign_" + data.loanId}><span></span></label>
                                            </div></td>
                                        <td>{data.assignUser}</td>
                                        <td> {data.loanApplyDate}</td>
                                        <td>{data.applicationStatus}</td>
                                        <td>{data.applicationId}</td>
                                        <td>{data.userName}</td>
                                        <td>{data.emailId}</td>
                                        <td>{data.mobileNumber}</td>

                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                    : ""}
            </div>
        </div>
        <div className="row" style={{ marginTop: '10px' }}>
            <div className="col-xs-12 text-right">
                {/* <button className="admin-pagination-btn" id="refresh" onClick={e => paginationHandler('refresh')}><span className="fa fa-refresh fa-spin" /></button> */}
                <button className="admin-pagination-btn" id="backword" onClick={e => paginationHandler('back')}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => paginationHandler('forward')}><span className="fa fa-chevron-right" /></button>
            </div>
        </div>
        {
            loanIdForAssign.length > 0 ?
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <button className="profile-save-btn" onClick={e => assignProceedHandler()}>Proceed</button>
                    </div>
                </div>
                : null
        }
    </div >
)

export default ManualAssign;