import React from 'react'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter, ComposedChart, Line,ReferenceLine,
} from 'recharts';

export default function BarChartComponent({sectionType,Data,ActiveMonthFilter,Heading}) {
    console.log(Data)
    const data = [
        {
           
            "value": 1000.00,
            "date": 5.0,
        },
        {
          
            "value": 400.00,
            "date": "2024-09-15"
        },
        {
          
            "value": 1800.00,
            "date": "2024-12-31"
        },
        {
           
            "value": 1800.00,
            "date": "2024-12-31"
        },
        {
          
            "value": 1800.00,
            "date": "2024-12-31"
        },
        {
          
            "value": 1800.00,
            "date": "2024-12-31"
        },
        {
           
            "value": 1800.00,
            "date": "2024-12-31"
        }
    ];
    return (
        <div className="MISDashboard">
            <div className="charts" style={{ marginTop: sectionType === 'ExpenseList' || sectionType === 'expenseTargetListing' ?'0px' : '20px',border:sectionType === 'ExpenseList' || sectionType === 'expenseTargetListing' ?'none' : '1px solid rgba(0, 0, 0, 0.25)' }}>
                {
                    sectionType === 'expenseTarget' ?
                    <h3>{Heading}</h3>
                :''}
                <div className="chartbar" style={{ height: sectionType === 'ExpenseList' ? window.innerWidth > 1800 ? '430px' : '366px':  sectionType === 'expenseTargetListing' ? window.innerWidth > 1800 ? '410px' : '370px' : window?.innerWidth > 1800  ? '500px' : '400px'  }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={300}
                            data={Data}
                            barSize={40}
                            // barSize={ActiveMonthFilter === '3month' ? 40 : ActiveMonthFilter === '6month' ? 30 : 20}
                            margin={{
                                top: 20,
                                right: 20,
                                left: 15,
                                bottom: 5,
                            }}
                            
                        >
                            <CartesianGrid stroke='none' />
                            <XAxis  dataKey="name"/>
                            <YAxis />
                            
          <ReferenceLine y={0} stroke="#000" />
                            <Tooltip />
        
                            <Bar dataKey="amount" fill="#8884d8" />
                            {/* <Line type="monotone" dataKey="value" stroke="#223D64" strokeWidth={2} dot={function customDot() { return null }} /> */}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
          
        </div>
    )
}
