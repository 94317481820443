import React, { useEffect, useState } from "react";
import { getAllPolicyApi, getCreditAnalysisData, updatePolicyDetails } from "./analysisactionCreator";
import Popup from '../../../presentationals/Popup/Popup.component';
import Loader from '../../../presentationals/Loader/DotLoader.component'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import styled from "@emotion/styled";
const PolicyDetails = ({ allDetail,admin }) => {
    const [policyId, setpolicyId] = useState('')
    const [policyList, setPolicyList] = useState('')
    const [policyData, setpolicyData] = useState([]);
    const [updatedData, setUpadtedData] = useState([]);
    const [sidePopUp, setSidepopup] = useState(false)
    const [popupStatus, setpopupStatus] = useState('')

    const [searchText, setSearchText] = useState('');
    const [loaderState, setloaderState] = useState(false);

    useEffect(() => {
        getdata()
    }, [])
    const getAnalysisdataNew = (id) => {
        getCreditAnalysisData(allDetail.userId, allDetail.loanId, id,admin.emailId, callback => {
            setpolicyData(callback)
            setloaderState(false)
        })
    }
    const getdata = () => {
        setloaderState(true)
        getAllPolicyApi(callback => {
            setPolicyList(callback)
            getAnalysisdataNew(callback[0].policyId)
            setpolicyId(callback[0].policyId)


        })
    }
    const filterHandler = (e) => {
        setpolicyId(e.target.value)

        getAnalysisdataNew(e.target.value)

    }
    const updatePassFail = (attributeId, updatedValue) => {
        const updatedPolicyData = policyData.map((data) => {

            if (data.attributeId === attributeId) {
                data.passFail = updatedValue;
            }
            return data;
        });
        setUpadtedData(updatedPolicyData);
    };
    const save = () => {
        let data = extractAttributes()
        if (data) {
            updatePolicyDetails(data, callback => {
                if (callback === 'success') {
                    setSidepopup(true)
                    setpopupStatus('Updated !')
                    removePopup()
                }
            })
        }
    }


    const extractAttributes = () => {
        return policyData.map((data) => {
            const loanId = data.loanId || allDetail.loanId;
            return {
                loanId,
                policyId: data.policyId,
                attributeId: data.attributeId,
                passFail: data.passFail,
                policyDescription: data.policyDescription,
                addedOn: data.addedOn,
                updatedOn: data.updatedOn,
                id: data.id,
            };
        });
    };
    const groupDataByCategory = (dataArray) => {
        const groupedData = {};

        dataArray.forEach(item => {
            const category = item.categoryName;
            if (!groupedData[category]) {
                groupedData[category] = [];
            }
            groupedData[category].push(item.attributeName);
        });

        return groupedData;
    };
    const groupedData = groupDataByCategory(policyData);
    const handleSearchInputChange = (e) => {
        setSearchText(e.target.value);
    };

    const filterAttributes = () => {
        return policyData.filter((data) =>
            data && data.attributeName ? data.attributeName.toLowerCase().includes(searchText.toLowerCase()) : ""
        );
    };
    const closePopup = () => {
        setSidepopup(false)
    }
    const removePopup = () => {
        setTimeout(() => {
            setSidepopup(false)
        }, 5000);
    }

    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            fontSize: '2rem', // Adjust the font size as needed
        },
    }));


    return (
        <div className="container-fluid  " >
            {sidePopUp ?
                <Popup
                    popupStatus={popupStatus}
                    closePopup={closePopup}
                />
                : ""}
            {groupedData && loaderState === false ?
                <div className="animated fadeIn" style={{ background: '#fff', padding: '15px', borderRadius: '10px', marginBottom: '20px', minHeight: '600px' }}>
                    <div style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <input style={{ borderRadius: '5px' }}
                                    placeholder="Enter Attribute Name"
                                    className="form-control"
                                    value={searchText}
                                    onChange={handleSearchInputChange} />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <select style={{ borderRadius: '5px' }} className="form-control" value={policyId} onChange={(e) => filterHandler(e)}>
                                    <option value={''}>Select Policy</option>
                                    {policyList && policyList.map((data, i) => {

                                        return (
                                            <option value={data.policyId}>{data.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <div className="text-right">
                                    <button className="btn btn-primary" style={{ background: '#2B78FF' }} onClick={() => save()}>Save Policy</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div style={{ width: '100%', background: 'rgba(0, 0, 0, 0.25)', minHeight: '1px', marginBottom: '10px' }}></div>
      
                    <div className='credit-table-finbit'>
                        <table className="text-center">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left' }}>Process</th>
                                    <th>Details</th>
                                    {/* <th style={{ textAlign: 'right' }}>Pass/Fail</th> */}
                                </tr>
                            </thead>
                        </table>
                        {Object.keys(groupedData).map((categoryName, i) => (
                            <React.Fragment key={categoryName}>
                                <table style={{ borderBottom: '1px solid #aec7fa' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ background: '#e8f0fe', width: '20%' }}>{categoryName}</td>
                                            {filterAttributes().map((item, index) => (
                                                item.categoryName === categoryName ? (
                                                    <tr key={index}>
                                                        <td className="text-left" style={{ width: '100%' }}>{item.attributeName}
                                                            {/* {item.policyDescription ? <CustomTooltip  title={item.policyDescription ? item.policyDescription : 'NA'}>
                                                                <InfoIcon style={{ marginLeft: '8px', cursor: 'pointer' }} />
                                                            </CustomTooltip>
                                                                : ""} */}
                                                            {item.policyDescription ? (
                                                                <CustomTooltip title={item.policyDescription || 'NA'}>
                                                                    <InfoIcon style={{ marginLeft: '8px', cursor: 'pointer' }} />
                                                                </CustomTooltip>
                                                            ) : ""}
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={item.passFail}
                                                                onChange={(e) => {
                                                                    const updatedData = policyData.map(data => {
                                                                        if (data.attributeId === item.attributeId) {
                                                                            return { ...data, passFail: e.target.checked };
                                                                        }
                                                                        return data;
                                                                    });
                                                                    setpolicyData(updatedData);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ) : null
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>

                            </React.Fragment>
                        ))}




                        {/* {Object.keys(groupedData).map(categoryName => (
                                    <React.Fragment key={categoryName}>
                                        {filterAttributes().map((item, index) => {
                                            // Use item instead of groupedData[categoryName][index]
                                            return (
                                                <tr key={index}>
                                                    {index === 0 ? (
                                                        <td style={{ background: '#e8f0fe' }} rowSpan={filterAttributes().length}>{categoryName}</td>
                                                    ) : null}
                                                    <td className="text-left">{item.attributeName}</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={item.passFail}
                                                            onChange={(e) => {
                                                                const updatedData = policyData.map(data => {
                                                                    if (data.attributeName === item.attributeName) {
                                                                        return { ...data, passFail: e.target.checked };
                                                                    }
                                                                    return data;
                                                                });
                                                                setpolicyData(updatedData);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </React.Fragment>
                                ))} */}
                        {/* {Object.keys(groupedData).map(categoryName => (
                                    <React.Fragment key={categoryName}>
                                        {groupedData[categoryName].map((item, index) => {
                                            const attributeData = policyData.find(data => data.attributeName === item);
                                            return (
                                                <tr key={index}>
                                                    {index === 0 ? (
                                                        <td style={{ background: '#e8f0fe' }} rowSpan={groupedData[categoryName].length}>{categoryName}</td>
                                                    ) : null}
                                                    <td >{item}</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={attributeData.passFail}
                                                            onChange={(e) => {
                                                                const updatedData = policyData.map(data => {
                                                                    if (data.attributeName === item) {
                                                                        return { ...data, passFail: e.target.checked };
                                                                    }
                                                                    return data;
                                                                });
                                                                setpolicyData(updatedData);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </React.Fragment>
                                ))} */}

                    </div>

                </div>
                : ""}
            {loaderState ?
                <Loader />
                : ""}

        </div>
    )
}
export default PolicyDetails