import React from 'react';
import { downloadExperainReportApi } from '../../AdminActionCreator.component';
import { useEffect } from 'react';
import { useState } from 'react';

const Experian = ({ reportData ,allDetail}) => {
    const [loaderState, setLoaderState] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    useEffect(() => {
        downloadExperainReportApi(allDetail.userId, allDetail.applicationId, callback => {
          setDownloadLink(callback)
          // setLoaderState(false)
        })
      }, [])
      const downloadFile = (downloadLink, filename) => {
        setLoaderState(true)
        if (!downloadLink) return;
    
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = 'crif.xls';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoaderState(false)
    
      };
    return (
        <>
           <div className='text-right'>
            {downloadLink !== '' ?
              <button className='profile-save-btn' onClick={() => downloadFile(downloadLink, `CRIF-${allDetail.applicationId}.xls`)}>Download Report</button>
              : ""}
          </div>
        <div className='phocket-table-new'>

            <h2>SCORE</h2>
            <table>
                <tbody>
                    <tr>
                        <td>FCIREX Score</td>
                        <td><h4>{reportData.SCORE?.FCIREXScore || '-'}</h4></td>
                    </tr>
                </tbody>
            </table>

            <h2>Current Application</h2>
            <table>
                <tbody>
                    {Object.entries(reportData["Current Application"] || {}).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key.replace(/_/g, ' ')}</td>
                            <td>{value || '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h2>CAIS Account Summary</h2>
            <table>
                <tbody>
                    {Object.entries(reportData["CAIS Account Summary"] || {}).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key.replace(/_/g, ' ')}</td>
                            <td>{value || '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h2>Personal Variations</h2>
            <h4>Phone Variations</h4>
            <table>
                <tbody>
                    {reportData["Personal Variations"]?.["Phone Variations"]?.map((phone, index) => (
                        <tr key={index}>
                            <td>{phone || '-'}</td>
                        </tr>
                    )) || <tr><td>-</td></tr>}
                </tbody>
            </table>
            
            <h4>PAN Variations</h4>
            <table>
                <tbody>
                    {reportData["Personal Variations"]?.["Pan Variations"]?.map((pan, index) => (
                        <tr key={index}>
                            {Object.entries(pan).map(([key, value]) => (
                                <td key={key}>{`${key.replace(/_/g, ' ')}: ${value || '-'}`}</td>
                            ))}
                        </tr>
                    )) || <tr><td>-</td></tr>}
                </tbody>
            </table>

            <h2>CAIS Account Details</h2>
            {reportData["CAIS Account"]?.["CAIS_Account_Details"]?.map((account, index) => (
                <div key={index} className="account-details">
                    <h4>Account {index + 1}</h4>
                    <table>
                        <tbody>
                            {Object.entries(account).map(([key, value]) => (
                                key !== 'Payment History' ? (
                                    <tr key={key}>
                                        <td>{key.replace(/_/g, ' ')}</td>
                                        <td>{value || '-'}</td>
                                    </tr>
                                ) : null
                            ))}
                        </tbody>
                    </table>

                    {/* Single table for Payment History displaying months and years in reverse order */}
                    {account["Payment History"] && (
                        <div>
                            <h4>Payment History</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        {Object.keys(account["Payment History"]).length > 0 && 
                                            Object.keys(account["Payment History"][Object.keys(account["Payment History"])[0]]).map(month => (
                                                <th key={month}>{month}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(account["Payment History"]).reverse().map(([year, months]) => (
                                        <tr key={year}>
                                            <td>{year}</td>
                                            {Object.keys(months).map(month => (
                                                <td key={month}>{months[month] || '-'}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )) || <p>No account details available.</p>}
        </div>
        </>
    );
};

export default Experian;
