import React, { useEffect, useState } from "react";
import { saveEmpDataApi } from "../../../AdminActionCreator.component";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { SaveDepartmentList, UpdateDepartmentList } from "../../targetactioncreator";
import Loader from '../../../../../presentationals/Loader/Loader.component';
const EmployeesTarget = ({ editData }) => {
    const [loader, setLoader] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        departmentName: "",
    });

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!employeeData.departmentName) {
            formErrors.departmentName = "Department Name is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoader(true)
            console.log("Employee Data Saved", employeeData);
            if (editData.id) {

                UpdateDepartmentList(employeeData, callback => {
                    setLoader(false)
                    if (callback.message === 'success') {
                        setPopup(true);
                        setPopupStatus('Data Updated Successfully!');
                    } else {
                        setPopup(true);
                        setPopupStatus('Please try again later!');
                    }
                    removePopup();
                });
            } else {
                SaveDepartmentList(employeeData, callback => {
                    setLoader(false)
                    if (callback.message === 'success') {
                        setPopup(true);
                        setPopupStatus('Data Saved Successfully!');
                    } else {
                        setPopup(true);
                        setPopupStatus('Please try again later!');
                    }
                    removePopup();
                });
            }
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };
    useEffect(() => {
        if (editData.id) {
            setEmployeeData({
                departmentName: editData.departmentName || "",
                id: editData.id || '',
                departmentId: editData.departmentId || '',
            });
        }
    }, [editData]);


    return (
        <div className="container">
            {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
            {
                loader ?
                    <Loader />
                    : ''}
            <div className="target-box">
                <h3>Add/Update Department Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Department Name</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="departmentName"
                                        value={employeeData.departmentName}
                                        onChange={handleChange}
                                    />
                                    {errors.departmentName && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.departmentName}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Department Data</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmployeesTarget;
