import React, { useEffect, useState } from "react"; 
import { createBulKNachApi, getBukNachEntriesApi } from "../AdminActionCreator.component"; 
import Loader from '../../../presentationals/Loader/layerLoader.component'; 
import Popup from '../../../presentationals/Popup/Popup.component';

const CreateBulkNach = ({ allStatus }) => {
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const getTodayDate = () => formatDate(new Date());

    const [loanStatus, setLoanStatus] = useState('109');
    const [allUserDetail, setAllUserDetail] = useState([]);
    const [dataToSend, setDataToSend] = useState([]);
    const [loaderState, setloaderState] = useState(false);
    const [popupState, setPopupState] = useState(false);
    const [popupStatus, setpopupStatus] = useState('');
    const [paymentData, setPaymentData] = useState('');
    const [startDate, setstartDate] = useState(getTodayDate()); 
    const [endDate, setendDate] = useState(getTodayDate());

    useEffect(() => {
        getData(startDate, endDate);
    }, [startDate, endDate]);

    const getData = (startDate, endDate) => {
        setloaderState(true);
        getBukNachEntriesApi(startDate, endDate, callBack => {
            setAllUserDetail(callBack.data);
            setDataToSend([]);
            setloaderState(false);
        });
    };

    const handleDateChange = (setter) => (e) => {
        const date = new Date(e.target.value);
        setter(formatDate(date));
    };

    const changeHandler = (e) => {
        const newLoanStatus = e.target.value;
        setLoanStatus(newLoanStatus);
    };

    const selectAll = (e) => {
        if (e.target.checked) {
            const alluserIds = allUserDetail.map(dataNew => dataNew.loanId);
            setDataToSend(alluserIds);
            allUserDetail.forEach((_, i) => {
                document.getElementById('disburseIcici' + i).checked = true;
            });
        } else {
            setDataToSend([]);
            allUserDetail.forEach((_, i) => {
                document.getElementById('disburseIcici' + i).checked = false;
            });
        }
    };

    const changeHandlerCheckbox = (e, data) => {
        if (e.target.checked) {
            setDataToSend(prev => [...prev, data.loanId]);
        } else {
            setDataToSend(prev => prev.filter(id => id !== data.loanId));
        }
    };

    const submit = () => {
        if (dataToSend.length > 0) {
            setloaderState(true);
            createBulKNachApi(dataToSend, callback => {
                setloaderState(false);
                setPaymentData(JSON.parse(callback));
                setPopupState(true);
                setpopupStatus('Done Successfully!');
                removerpopup();
            });
        } else {
            setPopupState(true);
            setpopupStatus('Please select application Id');
            removerpopup();
        }
    };

    const removerpopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const closePopup = () => {
        setPopupState(false);
    };

    return (
        <div className="container-fluid">
            <h3><b>Create Bulk NACH</b></h3>
            {loaderState ? <Loader /> : ""}
            {popupState && 
                <Popup
                    closePopup={closePopup}
                    popupStatus={popupStatus}
                />
            }
            {!loaderState && (
                <>
                    <div className="row" style={{ marginTop: '12px', marginBottom: '12px' }}>
                        <div className="col-sm-2 col-xs-12">
                            <label>Start Date</label>
                            <input 
                                type="date" 
                                className="form-control" 
                                value={startDate.split('-').reverse().join('-')} 
                                onChange={handleDateChange(setstartDate)} 
                            />
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <label>End Date</label>
                            <input 
                                type="date" 
                                className="form-control" 
                                value={endDate.split('-').reverse().join('-')} 
                                onChange={handleDateChange(setendDate)} 
                            />
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <div style={{ marginTop: '30px', marginBottom: '5px' }}>
                                <input type="checkbox" onChange={selectAll} /> Select All  
                            </div>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                           <div style={{ marginTop: '20px', marginBottom: '5px' }}>
                               <button  className="btn btn-primary" onClick={submit}>Submit</button>
                           </div>
                       </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-xs-12">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Application ID</th>
                                        <th>Status</th>
                                        <th>Source</th>
                                        <th>Mobile Number</th>
                                        {paymentData !== '' && <th>Status</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUserDetail && allUserDetail.map((data, i) => (
                                        <tr key={data.applicationId}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    id={'disburseIcici' + i}
                                                    onChange={(e) => changeHandlerCheckbox(e, data)}
                                                />
                                            </td>
                                            <td>{data.applicationId}</td>
                                            <td>{data.status}</td>
                                            <td>{data.mandateBy}</td>
                                            <td>{data.mobile}</td>
                                            {paymentData !== '' && 
                                                Object.keys(paymentData).includes(data.loanId) && 
                                                <td>{paymentData[data.loanId] || "null"}</td>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CreateBulkNach;
