import React from "react";

const DatePopup = ({dateFilter,download,setPopup,eByETOShow,sDateShow}) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border" style={{ borderRadius: '10px',minHeight:'300px',color:'#000' }}>
            <div className="text-right">
                <i  className="fa fa-times text-danger" style={{cursor:'pointer'}}onClick={()=>setPopup(false)}></i>
            </div>
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">Download Report</h4>
                    <p style={{color:'red',textAlign:'left'}}>Note: It is advisable to download 1+ day data for Karza related APIs as they follow Coordinated Universal Time (UTC). (Indian Standard Time is 5 hours and 30 minutes ahead of Coordinated Universal Time)</p>

                </div>
            </div>
            <div className="row" style={{marginTop:'20px'}}>
                <div className="col-sm-6 col-xs-12">
                    <label>Start Date</label>
                    <input type="date" className="date-input-admin" style={{width:'100%'}} value={sDateShow} onChange={(e) => dateFilter(e, 'start')} />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <label>End Date</label>
                    <input type="date" className="date-input-admin" style={{width:'100%'}} value={eByETOShow} onChange={(e) => dateFilter(e, 'end')} />
                </div>
            </div>
            <div className="text-center" style={{marginTop:'30px'}}>
                <button className="btn btn-primary" onClick={()=>download()}>Download</button>
            </div>
            
        </div>
    </div>
)
export default DatePopup