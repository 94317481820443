import React, { useEffect, useState } from "react";
import { getAllExpenseCategory,deleteExpenseCategoryById } from "../../targetactioncreator";
import NodataImage from '../../../../../../images/nodata.png'
import Loader from '../../../../../presentationals/Loader/Loader.component';
import Popup from '../../../../../presentationals/Popup/Popup.component';
import moment from "moment";
const CategoryList = ({ editHandler,applyFilter,fromDate,toDate, ActiveMonthFilter,applyFilterState }) => {
    // const expenseData = {
    //     "categoryType": 'test',
    //     "categoryDescription": "test",
    //     "id":'1'

    // };
    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [expenseData, setexpenseData] = useState('')

    const handleDelete = (data) => {
        setLoader(true)
        deleteExpenseCategoryById(data.id,(callback)=>{
            setLoader(false)
            if (callback?.status == 200) {
                expenseList()
            }else{
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const closePopup = () => {
        setPopup(false);
    };

    useEffect(() => {
        if(applyFilter == true || ActiveMonthFilter !== ''){
        expenseList()
        }
    }, [applyFilter,ActiveMonthFilter])
    
    const expenseList = ()=>{
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        setLoader(true)
        getAllExpenseCategory("dateRequired",formattedFromDate,formattedToDate,callback => {
            setLoader(false)
            if(callback && callback?.status == 200 && callback?.data?.length > 0){
                setexpenseData(callback.data)
            }else{
                setexpenseData([])
            }
        })
        applyFilterState()
    }

    return (
        <div className="container Executor">
             {loader ? <Loader /> : ''}
                {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
                 {expenseData !== '' && expenseData.length > 0 ?
            <div
                className=""
                style={{
                    background: '#f2f2f2',
                    padding: '10px',
                    margin: '50px auto 0',
                    borderRadius: '5px',
                    overflow: 'auto',
                    maxWidth: '1000px'
                }}
            >
                <div className="">
                    <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th>Category Type</th>
                                <th>Category Description</th>

                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {expenseData !== '' && expenseData.length > 0 ? expenseData.map((data, i) => {
                                return (
                                    <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                        <td >{data?.categoryName}</td>
                                        <td >{data.categoryType}</td>
                                        <td>{data.categoryDescription}</td>
                                        <td>
                                            <i
                                                className="fa fa-pencil-square-o fa-lg"
                                                aria-hidden="true"
                                                onClick={() => editHandler(data)}
                                                style={{ cursor: 'pointer' }}
                                                aria-label="Edit Expense"
                                                title="Edit Expense"
                                            ></i>
                                        </td>
                                        <td>
                                            <i
                                                className="fa fa-trash-o fa-lg"
                                                aria-hidden="true"
                                                onClick={() => handleDelete(data)}
                                                style={{ cursor: 'pointer' }}
                                                aria-label="Delete Expense"
                                                title="Delete Expense"
                                            ></i>
                                        </td>
                                    </tr>
                                )
                            }) : ""}

                        </tbody>
                    </table>
                </div>
            </div>
             :
             <div className="text-center" style={{marginTop: '50px'}}>
             <img src={NodataImage} width={'40%'}/>
             </div>
             }
        </div>
    );
};

export default CategoryList;
