import React, { useState } from "react";
import ExpensesAdd from "./add";
import ExpensesList from "./list";
import moment from "moment";
import TuneIcon from '@mui/icons-material/Tune';
const differenceDate = () => {
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

const InitalDate = (type) => {
    const FirstDay = moment().subtract(differenceDate(), 'days');
    if (type === 'fromDate') {
        const fromdate = FirstDay.format('YYYY-MM-DD');
        return fromdate
    } else if (type === 'toDate') {
        const todate = moment(new Date).format('YYYY-MM-DD')
        return todate
    }
}

const MainCategory = () => {
    const [filterType, setFilterType] = useState('date')
    const [viewType, setviewType] = useState('list')
    const [editData, seteditData] = useState('')
    const [ActiveMonthFilter, setActiveMonthFilter] = useState('3month')
    const [fromDate, setFromDate] = useState(InitalDate('fromDate'))
    const [toDate, setToDate] = useState(InitalDate('toDate'))
    const [clearbtnShow, setClearBtnShow] = useState(false);
    const [applyFilter, setapplyFilter] = useState(false);
    const [clearFilter, setclearFilter] = useState(false);

    const editHandler = (data) => {
        console.log(data)
        if (data.id) {
            seteditData(data)
            setviewType('add')
        }



    }
    const TabwiseDate = (type) => {
        const today = moment();  
        let fromDate, toDate;

        // toDate = today.endOf('month').format('YYYY-MM-DD');
        toDate = today.subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        if(type == '3month'){
            fromDate = today.subtract(2, 'months').startOf('month').format('YYYY-MM-DD');
        }else if(type == '6month'){
            fromDate = today.subtract(5, 'months').startOf('month').format('YYYY-MM-DD');
        }else if(type == '1year'){
            fromDate = today.subtract(11, 'months').startOf('month').format('YYYY-MM-DD');
        }
        setFromDate(fromDate);
    setToDate(toDate);
    }
    const ChangeScreen=(type)=>{
        seteditData('')
        setviewType(type)
    }
    const handlemonthFilter = (type) => {
        setActiveMonthFilter(type)
        TabwiseDate(type)
        setClearBtnShow(true)
    }

    const handleDate = (e, type) => {
        if (type === 'fromDate') {
            setFromDate(e.target.value)
            setClearBtnShow(true)

        } else if (type === 'toDate') {
            setToDate(e.target.value)
            setClearBtnShow(true)

        } else if (type === 'applyFilter') {
            setapplyFilter(true);
        } else if (type === 'clearFilter') {
            setclearFilter(true)
            setClearBtnShow(false)
            setapplyFilter(true);
            setActiveMonthFilter('3month')
            setFromDate(InitalDate('fromDate'))
            setToDate(InitalDate('toDate'))
        }
    }

    const applyFilterState = ()=>{
        setapplyFilter(false);
    }

    const handlefilterType =(e)=>{
        setFilterType(e.target.value)
        setclearFilter(true)
        setClearBtnShow(false)
        setapplyFilter(true);
        if(e.target.value == 'month'){
            setActiveMonthFilter('3month')
            TabwiseDate('3month')
        }else if(e.target.value == 'date'){
            setFromDate(InitalDate('fromDate'))
            setToDate(InitalDate('toDate'))
        }
    }

    return (
        <div className="container expense-config">
            <div style={{ display: 'flex', textAlign: 'center' ,marginTop:'30px',marginBottom:'-30px',marginLeft:'80px'}}>
                <div onClick={() => ChangeScreen('list')} style={{ color: viewType === 'list' ? '#fff' : '#000', background: viewType === 'list' ? '#337ab7' : '#f4f3f1', padding: '10px', borderRadius: '5px', width: '40px', height: '40px', cursor: 'pointer' }}> <i className="fa fa-list" ></i></div>&nbsp;&nbsp;
                <div onClick={() => ChangeScreen('add')} style={{ color: viewType === 'add' ? '#fff' : '#000', background: viewType === 'add' ? '#337ab7' : '#f4f3f1', padding: '10px', borderRadius: '5px', width: '40px', height: '40px', cursor: 'pointer' }}> <i className="fa fa-plus" ></i></div>

            </div>
            {viewType === 'list' ?
                <div className="customfilterbtn Filterations" style={{ marginTop: '50px' ,marginLeft:'80px'}}>
                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12"style={{ marginTop: '10px' }}>
                            <div className="inputgrp">
                                <label htmlFor="" className='inputlabel'><TuneIcon style={{fontSize:'18px',marginBottom:'-4px'}}/>&nbsp;Filter Type</label>
                                <select className='inputtag' value={filterType} onChange={(e) => handlefilterType(e)}>
                                    <option value="date">Date Wise</option>
                                    <option value="month">Month Wise</option>
                                </select>
                            </div>
                        </div>
                        {
                            filterType === 'date' ?
                                <>

                                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12"style={{ marginTop: '10px' }}>
                                        <div className="inputgrp">
                                            <label htmlFor="fromDate" className='inputlabel'>From</label>
                                            <input type="date" id='customDateInput' className='inputtag' value={fromDate} onChange={(e) => handleDate(e, 'fromDate')} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12"style={{ marginTop: '10px' }}>
                                        <div className="inputgrp">
                                            <label htmlFor="toDate" className='inputlabel'>To</label>
                                            <input type="date" id='customDateInput' className='inputtag' value={toDate} min={fromDate} onChange={(e) => handleDate(e, 'toDate')} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12"style={{ marginTop: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button className='filterbtn' style={{ marginTop: '36px', marginRight: '20px' }} onClick={(e) => handleDate(e, 'applyFilter')} >Apply Filter</button>
                                        {
                                            clearbtnShow ?
                                                <button className='filterbtnReverse' style={{ marginTop: '36px' }} onClick={(e) => handleDate(e, 'clearFilter')}>Clear Filter</button>
                                                : ''}
                                    </div>
                                    </div>
                                </>
                                : ''}
                        {
                            filterType === 'month' ?
                                <div className="col-lg-6 col-md-6 col-xs-12"style={{ marginTop: '10px' }}>
                                    <div className="inputgrp" style={{ marginTop: '25px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <button className={`filterbtn2 ${ActiveMonthFilter === '3month' ? 'active' : ''}`} onClick={() => handlemonthFilter('3month')} style={{width:'100%'}} >last 3 Month</button>
                            <button className={`filterbtn2 ${ActiveMonthFilter === '6month' ? 'active' : ''}`} onClick={() => handlemonthFilter('6month')} style={{width:'100%'}} >last 6 Month</button>
                            <button className={`filterbtn2 ${ActiveMonthFilter === '1year' ? 'active' : ''}`} onClick={() => handlemonthFilter('1year')}  style={{width:'100%'}}>last 1 Year</button>
                            
                        </div>

                    </div>
                                </div>
                                : ''}
                       

                    </div>
                   
                </div>
                : ''}
            {/* {viewType === 'list' ?
            <div className="customfilterbtn Filterations" style={{marginLeft:'80px'}}>
                  <div className="row" style={{marginTop:'60px'}}>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div className="inputgrp">
                                <label htmlFor="fromDate" className='inputlabel'>From</label>
                                <input type="date" id='fromDate' className='inputtag' value={fromDate} onChange={(e) => handleDate(e, 'fromDate')} />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div className="inputgrp">
                                <label htmlFor="toDate" className='inputlabel'>To</label>
                                <input type="date" id='toDate' className='inputtag' value={toDate} min={fromDate} onChange={(e) => handleDate(e, 'toDate')} />
                            </div>
                        </div>
                    

                    </div>
                <div className="inputgrp" style={{ marginTop: '20px' }}>
                    <label className='inputlabel'>Month Filter</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button className={`filterbtn2 ${ActiveMonthFilter === '3month' ? 'active' : ''}`} onClick={() => handlemonthFilter('3month')} >last 3 Month</button>
                        <button className={`filterbtn2 ${ActiveMonthFilter === '6month' ? 'active' : ''}`} onClick={() => handlemonthFilter('6month')} >last 6 Month</button>
                        <button className={`filterbtn2 ${ActiveMonthFilter === '1year' ? 'active' : ''}`} onClick={() => handlemonthFilter('1year')} >last 1 Year</button>
                        <button className='filterbtn' style={{ marginTop: '10px', marginRight: '20px' }} onClick={(e) => handleDate(e, 'applyFilter')} >Apply Filter</button>
                        {
                                clearbtnShow ?
                                    <button className='filterbtnReverse' style={{ marginTop: '10px' }} onClick={(e) => handleDate(e, 'clearFilter')}>Clear Filter</button>
                                    : ''}
                    </div>
                </div>
            </div>
            :''} */}
            {viewType === 'list' ?
                <ExpensesList  editHandler={editHandler} ActiveMonthFilter={ActiveMonthFilter} applyFilter={applyFilter} fromDate={fromDate} toDate={toDate} applyFilterState={applyFilterState}/>
                : ""}
            {viewType === 'add' ?
                <ExpensesAdd editData={editData} />

                : ""}
        </div>
    )
}
export default MainCategory